<template>
	<div class="clearfix loading-page-container">
		<div class="main-box">
			<div class="animated-logo-container">
				<video autoplay loop muted>
					<source :src="tgsAnimatedLogo" type="video/mp4" />
				</video>
			</div>
			<div class="logo-content">
				<img
					class="navbar-brand-full"
					src="img/brand/thegreenshot-brand.png"
					onclick="document.location.href='my-dashboard#/my-dashboard'"
					alt="TheGreenShot"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import tgsAnimatedLogo from './../../../public/videos/tgs-logo-animation.mp4';

export default {
	name: 'LoaderMainWaitingPage',
	data() {
		return {
			tgsAnimatedLogo
		};
	}
};
</script>

<style lang="scss" scoped>
.loading-page-container {
	background-color: #fff;
	width: 100vw;
	height: 100vh;
	position: relative;

	.main-box {
		display: flex;
		justify-content: start;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		align-items: center;
		max-width: 100%;

		.animated-logo-container {
			margin-right: 20px;
			display: inline-block;
			width: auto;
			height: 200px;

			video {
				display: inline;
				height: 100%;
			}
		}

		.logo-content {
			height: 130px;
			overflow: hidden;
			width: 280px;
			position: relative;

			img {
				display: inline-block;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
			}
		}
	}
}

@media (max-width: 1250px) {
	.loading-page-container .main-box {
		.animated-logo-container {
			height: 100px;
		}
		.logo-content {
			height: 65px;
			width: 140px;
		}
	}
}

@keyframes logoAnimation {
	from {
		opacity: 0;
		transform: translateX(-50px);
	}

	to {
		opacity: 1;
		transform: translateX(0);
	}
}
</style>
